.slick-slider {
  /* background-color: aquamarine; */
  padding-left: 20px;
}

.slick-prev {
  z-index: 1;
  /* background-color: red !important; */
}
.slick-next {
  z-index: 1;
  /* background-color: red !important; */
}

.slick-dots li button:before {
  color: rgb(179, 178, 178) !important;
  font-size: 10px !important;
  margin-top: 10px;
}

.slick-dots li.slick-active button:before {
  color: white !important;
  /* font-size: 12px !important; */
}

button {
  width: 70px;
  height: 30px;
  outline: none;
  border: none;
  background-color: #0d1117;
  border: 1px solid rgb(49, 54, 61);
  color: rgba(255, 255, 255, 0.5);
  color: rgb(179, 178, 178) !important;
}
